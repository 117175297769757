import {React, useEffect, useRef} from 'react';
import useSticky from './useSticky';

import {
  ChakraProvider,
  Box,
  Text,
  theme,
  Divider,
  Spacer,
  Stack,
  Button, List, ListItem, ListIcon, Highlight, Card, CardBody, CardHeader, Image, SimpleGrid, Icon, Link
} from '@chakra-ui/react';

import { FaCheck, FaHandshake, FaUserPlus, FaRegCalendarCheck } from "react-icons/fa";
import { BsController, BsTools } from "react-icons/bs";
import { FiGlobe } from "react-icons/fi";
import { CgMusic } from "react-icons/cg";

import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

function App() {
  const { sticky, stickyRef } = useSticky();

  const ref = useRef([]);
  ref.current = [];

  useEffect(() => {
    ref.current.forEach((el, index) => {
      const startValue = index % 2 === 0 ? "top bottom-=150" : "top bottom-=250";
      gsap.fromTo(el,
        { y: 100, autoAlpha: 0 },
        {
          y: 0,
          autoAlpha: 1,
          duration: 0.5,
          scrollTrigger: {
            trigger: el,
            start: startValue,
            toggleActions: "play none none reverse",
          },
        });
    });

    // Cleanup function to kill ScrollTriggers
    return () => {
      ScrollTrigger.getAll().forEach((trigger) => {
        if (ref.current.includes(trigger.trigger)) {
          trigger.kill();
        }
      });
    };
  }, []); // Dependency array is empty, so this effect runs only on mount and unmount

  const addToRefs = (el) => {
    if (el && !ref.current.includes(el)) {
      ref.current.push(el);
    }
  };

  return (
    <ChakraProvider theme={theme} >
      <Box h={10} bg='#eff3f4'></Box>
      {/* navbar */}
      <Box ref={stickyRef} as='nav' className={sticky?'sticky gostick':'sticky'} backdropFilter="saturate(180%) blur(20px)">
        <Stack h={16} direction='row' position='sticky' px={{base: '5%',md: '10%', xl: '20%'}}>
          <Text fontSize='xl' fontWeight='600' align='left' my='auto'>
            Ça, c'est William
          </Text>
          <Spacer />
          <Button as='a' href="mailto: william@charbonn.io" bg='#7db8d1' color='white' borderRadius={50} height={7} my='auto'>Recruter</Button>
        </Stack>
        <Divider className={sticky?'divider':''} border={'0.1px solid #c7c7c7'} mx='auto' maxW={{base: '100%',md: '80%', lg: '80%', xl: '60%'}}/>
      </Box>
      <Box w='100%' py={{base: 10, lg:100}} px={{base: '5%',md: '10%', xl: '20%'}} bg='#eff3f4'>
        <Stack direction='row' spacing={2}>
          <Stack direction='column' w='100%'>
            {/* Before qualites */}
            <Box py={{base:20, lg:24, xl:32}} px={10} mb={{md: 320, lg: 200, xl: 100}}>
              <List spacing={0}>
                <ListItem  p={0}>
                  <ListIcon as={FaCheck} boxSize={{base:6, lg:10, xl: 12}} color='green.300' />
                  <Text as='b' fontSize={{ base: "34px", md:"42px", lg:"54px", xl: "70px", "2xl": "80px"}} lineHeight={1.2}>Curieux</Text>
                </ListItem>
                <ListItem>
                  <ListIcon as={FaCheck} boxSize={{base:6, lg:10, xl: 12}} color='green.300' />
                  <Text as='b' fontSize={{ base: "34px", md:"42px", lg:"54px", xl: "70px", "2xl": "80px"}} lineHeight={1.2}>Polyvalent</Text>
                </ListItem>
                <ListItem>
                  <ListIcon as={FaCheck} boxSize={{base:6, lg:10, xl: 12}} color='green.300' />
                  <Text as='b' fontSize={{ base: "34px", md:"42px", lg:"54px", xl: "70px", "2xl": "80px"}} lineHeight={1.2}>Débrouillard</Text>
                </ListItem>
                <ListItem>
                  <ListIcon as={FaCheck} boxSize={{base:6, lg:10, xl: 12}} color='green.300' display={{base: 'flow', sm: 'initial'}} visibility='hidden'/>
                  <Text as='b' fontSize={{ base: "34px", md:"42px", lg:"54px", xl: "70px", "2xl": "80px"}} lineHeight={1.2}><Highlight query='Ça' styles={{ px: '4', py: '0', bg: 'green.300' }}>
                    Ça
                  </Highlight> c'est William.</Text>
                </ListItem>
              </List>
            </Box>
            {/* Before first two cards */}
            <SimpleGrid columns={{base:1, sm: 1, md: 4, lg: 5}} gap={{base:0, md: 4, lg:5}} mx={{base: 0, md:'-10%'}}>
              <Box ref={addToRefs} gridColumnStart={{sm: 1, md: 1}} gridColumnEnd={{sm:1, md:3, lg: 4}} mb={{base: 4, md: 0}}>
                <Card bg='black' borderRadius={24} px={{base: 5, lg: 10}} py={{base: 5, sm: 5, md: 20, lg: 10}} mb={{base: 5, md: 0}} h='100%'>
                  <CardHeader>
                    <Text as='b' lineHeight={1} fontSize={{ base: "24px", lg:"32px", xl: "40px", "2xl": "32px"}} color='white'>Bachelier d'une grande université.</Text>
                    <Spacer h={{base: 1, lg:1}}/>
                    <Text fontWeight={500} lineHeight={1.2} fontSize={{ base: "14px", lg:"16px", xl: "18px", "2xl": "20px"}} color='white'>William est diplômé en informatique - et a obtenu un DEC dans le même domaine.</Text>
                  </CardHeader>
                  <CardBody>
                    <Image src='/images/udes_logo_irl.jpg' alt='USherbrooke' />
                  </CardBody>
                </Card>
              </Box>
              <Box ref={addToRefs} gridColumnStart={{sm: 1, md: 3, lg: 4}} gridColumnEnd={{sm:1, md: 5, lg: 6}}>
                <Card bg='black' borderRadius={24} px={{base: 5, lg: 10}} py={{base: 5,sm: 5, md: 5, lg: 10}} h='100%'>
                  <CardBody>
                    <FaHandshake size={100} color="white" />
                    <Stack direction='column' spacing={0}>
                      <Text as='b' fontWeight={900} lineHeight={1.2} fontSize={{ base: "24px", lg:"32px", xl: "40px", "2xl": "48px"}} color='white'>Vous recrutez?</Text>
                      <Text as='i' fontWeight={900} lineHeight={1.2} fontSize={{ base: "24px", lg:"32px", xl: "40px", "2xl": "48px"}} color='green.300'>PENSEZ </Text>
                      <Text as='i' fontWeight={900} lineHeight={1.2} fontSize={{ base: "24px", lg:"32px", xl: "40px", "2xl": "48px"}} color='green.300'>ALLER</Text>
                      <Stack direction='row' mb={10}>
                        <Icon as={FaUserPlus} boxSize={{base:6, lg:10, xl: 12}} color='yellow.300'></Icon>
                        <Text as='i' fontWeight={900} lineHeight={1.2} fontSize={{ base: "24px", lg:"32px", xl: "40px", "2xl": "48px"}} color='green.300'>WILLIAM.</Text>
                      </Stack>
                      <Text fontWeight={500} lineHeight={1.2} fontSize={{ base: "14px", lg:"16px", xl: "18px", "2xl": "20px"}} color='white'>William possède une foule de connaissances et d'intérêts - Entity Framework, ReactJS et MSSQL, entre autres. Tout pour du développement Full Stack.</Text>
                    </Stack>
                  </CardBody>
                </Card>
              </Box>
            </SimpleGrid>
            {/* Before next two cards */}
            <SimpleGrid columns={{base:1, sm: 1, md: 4, lg: 5}} gap={{base:0, md: 4, lg:5}} mx={{base: 0, md:'-10%'}} mt={4}>
              <Box ref={addToRefs} gridColumnStart={{sm: 1, md: 1}} gridColumnEnd={{sm:1, md:3, lg: 3}} mb={{base: 4, md: 0}}>
                <Card bg='black' borderRadius={24} px={{base: 5, lg: 10}} py={{base: 5, sm: 5, md: 20, lg: 10}} mb={{base: 5, md: 0}} h='100%'>
                  <CardBody py={10}>
                    <FaRegCalendarCheck size={140} color="white"/>
                    <Stack direction='column' spacing={0} mt={10}>
                      <Text as='b' fontWeight={900} lineHeight={1.2} fontSize={{ base: "24px", lg:"32px", xl: "40px", "2xl": "48px"}} color='white'>Prêt à l'emploi,</Text>
                      <Text as='b' fontWeight={900} lineHeight={1.2} fontSize={{ base: "24px", lg:"32px", xl: "40px", "2xl": "48px"}} color='white'>dès </Text>
                      <Text as='i' fontWeight={900} lineHeight={1.2} fontSize={{ base: "24px", md: "32px", lg:"32px", xl: "32px", "2xl": "42px"}} color='green.300'>MAINTENANT.</Text>
                    </Stack>
                  </CardBody>
                </Card>
              </Box>
              <Box ref={addToRefs} gridColumnStart={{sm: 1, md: 3, lg: 3}} gridColumnEnd={{sm:1, md: 5, lg: 6}}>
                <Card bg='white' borderRadius={24} px={{base: 5, lg: 10}} py={0} h='100%' variant='unstyled'>
                  <CardBody py={0}>
                    <Stack direction='column' spacing={10} mb={20}>
                      <Image src='/images/technologies.jpg' alt='Technologies'/>
                      <Stack direction='column' spacing={0} m='auto'>
                        <Text as='b' fontWeight={900} lineHeight={1.2} fontSize={{ base: "44px", lg:"50px", xl: "58px", "2xl": "64px"}} color='black'>Oui, ça</Text>
                        <Text as='b' fontWeight={900} lineHeight={1.2} fontSize={{ base: "44px", lg:"50px", xl: "58px", "2xl": "64px"}} color='black'>passionne </Text>
                        <Text as='b' fontWeight={900} lineHeight={1.2} fontSize={{ base: "44px", lg:"50px", xl: "58px", "2xl": "64px"}} color='black'>William.</Text>
                      </Stack>
                    </Stack>
                  </CardBody>
                </Card>
              </Box>
            </SimpleGrid>
          </Stack>
        </Stack>
      </Box>
      {/* Before footer */}
      <Stack py={{base: 10, lg:100}} px={{base: '5%',md: '10%', xl: '15%'}} direction='column' bg='white' w='100%'>
        <Text as='b' fontWeight={900} lineHeight={1.2} fontSize={{ base: "44px", lg:"50px", xl: "58px", "2xl": "64px"}} color='black'>Encore plus de raisons de choisir William.</Text>
        <SimpleGrid columns={{base:1, sm: 1, md: 2, xl: 4}} gap={{base:4, xl: 4, md:5}} mx={{base: 0, md: 0}} mt={10}>
              <Box gridColumnStart={{sm: 1, md: 1, xl: 1}} gridColumnEnd={{sm:1, md:2, xl: 2}} gridRowStart={{sm: 1, md: 1, xl: 1}} gridRowEnd={{sm: 2, md: 2, xl: 1}}>
                <Card bg='white' borderRadius={24} mb={{base: 5, md: 0}} pr={4} h='100%' variant='unstyled'>
                  <CardBody py={0}>
                    <BsController style={{strokeWidth: '0.1px'}} size={80} color="black"/>
                    <Stack direction='column' spacing={0} mt={3}>
                      <Text as='b' fontWeight={600} lineHeight={1.2} fontSize={{ base: "21px", lg:"24px", xl: "28px", "2xl": "32px"}} color='black'>Jeux vidéo</Text>
                    </Stack>
                    <Text my={3} lineHeight={1.2} fontSize={{ base: "14px", lg:"16px", xl: "18px", "2xl": "20px"}} color='black'>Bien que la majorité de son expérience avec le moteur Unity soit à travers des projets personnels, William s'en est aussi servi au courant de son parcours académique.</Text>
                    <Link color='#7db8d1' href='https://willthehuman.itch.io/' isExternal>En savoir plus &gt;</Link>
                  </CardBody>
                </Card>
              </Box>
              <Box gridColumnStart={{sm: 1, md: 2, xl: 2}} gridColumnEnd={{sm:1, md: 3, xl: 3}} gridRowStart={{sm: 2, md: 1, xl: 1}} gridRowEnd={{sm: 3, md: 2, xl: 1}}>
                <Card bg='white' borderRadius={24} mb={{base: 5, md: 0}} pr={4} h='100%' variant='unstyled'>
                  <CardBody py={0}>
                    <FiGlobe style={{strokeWidth: '1.3px'}} size={80} color="black"/>
                    <Stack direction='column' spacing={0} mt={3}>
                      <Text as='b' fontWeight={600} lineHeight={1.2} fontSize={{ base: "21px", lg:"24px", xl: "28px", "2xl": "32px"}} color='black'>Web</Text>
                    </Stack>
                    <Text my={3} lineHeight={1.2} fontSize={{ base: "14px", lg:"16px", xl: "18px", "2xl": "20px"}} color='black'>Ce site constitue le 3<sup>e</sup> projet d'une expérience nommée "Un projet par semaine jusqu'à mon embauche".</Text>
                    <Link color='#7db8d1' href='https://www.linkedin.com/in/williamcperrier/' isExternal>En savoir plus &gt;</Link>
                  </CardBody>
                </Card>
              </Box>
              <Box gridColumnStart={{sm: 1, md: 1, xl: 3}} gridColumnEnd={{sm:1, md: 2, xl: 4}} gridRowStart={{sm: 3, md: 2, xl: 1}} gridRowEnd={{sm: 4, md: 3, xl: 1}}>
                <Card bg='white' borderRadius={24} mb={{base: 5, md: 0}} pr={4} h='100%' variant='unstyled'>
                  <CardBody py={0}>
                    <CgMusic style={{strokeWidth: '0.1px'}} size={80} color="black"/>
                    <Stack direction='column' spacing={0} mt={3}>
                      <Text as='b' fontWeight={600} lineHeight={1.2} fontSize={{ base: "21px", lg:"24px", xl: "28px", "2xl": "32px"}} color='black'>Musique</Text>
                    </Stack>
                    <Text my={3} lineHeight={1.2} fontSize={{ base: "14px", lg:"16px", xl: "18px", "2xl": "20px"}} color='black'>William sait utiliser la station audionumérique Ableton pour produire de la musique. Dans le cadre d'un contrat, il a pu mettre en oeuvre cet atout.</Text>
                    <Link color='#7db8d1' href='https://whyp.it/collections/1689/unreleased-game-soundtracks' isExternal>En savoir plus &gt;</Link>
                  </CardBody>
                </Card>
              </Box>
              <Box gridColumnStart={{sm: 1, md: 2, xl: 4}} gridColumnEnd={{sm:1, md: 3, xl: 5}} gridRowStart={{sm: 4, md: 2, xl: 1}} gridRowEnd={{sm: 5, md: 3, xl: 1}}>
                <Card bg='white' borderRadius={24} mb={{base: 5, md: 0}} pr={4} h='100%' variant='unstyled'>
                  <CardBody py={0}>
                    <BsTools style={{strokeWidth: '0.1px'}} size={80} color="black"/>
                    <Stack direction='column' spacing={0} mt={3}>
                      <Text as='b' fontWeight={600} lineHeight={1.2} fontSize={{ base: "21px", lg:"24px", xl: "28px", "2xl": "32px"}} color='black'>Réparation</Text>
                    </Stack>
                    <Text my={3} lineHeight={1.2} fontSize={{ base: "14px", lg:"16px", xl: "18px", "2xl": "20px"}} color='black'>Le passe-temps déterminant de William est la réparation d'appareils électroniques. Plus particulièrement, le diagnostic de circuits électroniques et la microsoudure.</Text>
                  </CardBody>
                </Card>
              </Box>
            </SimpleGrid>
      </Stack>
    </ChakraProvider>
  );
}

export default App;
